<template>
  <el-dialog
    :title="detailData && detailData.id ? '编辑' : '添加'"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    destroy-on-close
    width="40%"
  >
    <div>
      <el-form ref="dataForm" :model="dataForm" :rules="rules"
        ><el-form-item prop="title">
          <TextInput
            v-model="dataForm.title"
            :config="{
              type: 'input',
              required: true,
              label: '标题：',
              width: '100%',
            }"
          >
          </TextInput>
        </el-form-item>
        <el-form-item prop="thumbnail">
          <TextInput
            :config="{
              type: 'slot',
              required: true,
              label: '缩略图：',
              width: '100%',
            }"
          >
            <div>
              <div @click="uploadFun">
                <img
                  v-if="dataForm.thumbnail"
                  class="upload"
                  :src="dataForm.thumbnail"
                />
                <div
                  v-else
                  class="upload"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i class="el-icon-plus"></i>
                </div>
              </div>

              <span style="font-weight: 400; font-size: 14px; color: #999999"
                >建议宽高比16:9 格式:jpg/jpeg/png
              </span>
            </div>
          </TextInput>
        </el-form-item>
        <el-form-item prop="isEnabled">
          <TextInput
            v-model="dataForm.isEnabled"
            :config="{
              type: 'radio',
              label: '是否显示：',
              width: '100%',
              option: [
                {
                  label: '启用',
                  value: 1,
                },
                {
                  label: '禁用',
                  value: 0,
                },
              ],
            }"
          >
          </TextInput>
        </el-form-item>
        <el-form-item prop="readCount">
          <TextInput
            v-model="dataForm.readCount"
            :config="{
              type: 'inputNumber',
              label: '阅读数量：',
              width: '100%',
            }"
          >
          </TextInput>
        </el-form-item>
        <el-form-item prop="sortOrder">
          <TextInput
            v-model="dataForm.sortOrder"
            :config="{
              type: 'inputNumber',
              label: '排序：',
              width: '100%',
            }"
          >
          </TextInput>
        </el-form-item>
        <el-form-item prop="content">
          <TextInput
            :config="{
              type: 'slot',
              label: '内容：',
              width: '100%',
            }"
          >
            <quill-editor
              ref="QuillEditor"
              v-model="dataForm.content"
              :options="editorOption"
              :height="400"
            ></quill-editor>
          </TextInput>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
      <el-button @click="submitForm" type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
import { addTrafficArticle } from "@/api/newEnergyclaim.js";
import _ from "lodash";
import { uploadFile } from "@/utils/index";
import { OSSDirectPass } from "@/api/oss.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
export default {
  name: "ConsultingAdd",
  components: { TextInput, quillEditor },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(n) {
      if (n) {
        this.dataForm = _.cloneDeep(this.detailData);
      }
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
    // detailData: {
    //   deep: true,
    //   handler(n) {
    //     if (n) {
    //       this.dataForm = _.cloneDeep(n);
    //     }
    //   },
    // },
  },
  data() {
    return {
      dialogVisible: false,
      editorOption: {
        modules: {
          toolbar: {
            container: [
              // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ["bold", "italic", "underline", "strike"],
              // 引用  代码块-----['blockquote', 'code-block']
              ["blockquote", "code-block"],
              // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{ header: 1 }, { header: 2 }],
              // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{ list: "ordered" }, { list: "bullet" }],
              // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{ script: "sub" }, { script: "super" }],
              // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{ indent: "-1" }, { indent: "+1" }],
              // 文本方向-----[{'direction': 'rtl'}]
              [{ direction: "rtl" }],
              // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{ size: ["small", false, "large", "huge"] }],
              // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{ color: [] }, { background: [] }],
              // 字体种类-----[{ font: [] }]
              [{ font: [] }],
              // 对齐方式-----[{ align: [] }]
              [{ align: [] }],
              // 清除文本格式-----['clean']
              ["clean"],
              // 链接、图片、视频-----['link', 'image', 'video']
              ["link", "image"],
            ],
            handlers: {
              image(value) {
                uploadFile({ accept: "image/*", fileSize: 10 }).then(
                  async (res) => {
                    let url = await OSSDirectPass({
                      name: res.name,
                      raw: res,
                    });
                    let quill = this.quill;
                    let length = quill.getSelection().index;
                    // let text = `<img src="${url}></img>`;
                    // const delta = quill.deltaFromHtml(text);
                    // quill.setDelta(delta);
                    quill.insertEmbed(length, "image", url);
                    quill.setSelection(length + 1);
                  }
                );
              },
            },
          },
        },
        placeholder: "请输入内容",
        theme: "snow",
      },
      dataForm: {
        // id: "",
        // serviceName: "",
        // serviceStandard: "",
        // serviceAmount: "",

        content: "",
        isEnabled: 1,
        readCount: 0,
        sortOrder: 0,
        title: "",
        thumbnail:
          "https://yili-platform-v2.oss-cn-shenzhen.aliyuncs.com/common/jtanzx/ui/firm-no-icon-20240801.png",
      },
      rules: {
        title: [
          {
            required: true,
            message: "请添加标题",
            trigger: "change",
          },
        ],
        thumbnail: [
          {
            required: true,
            message: "请添加缩略图",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    //清空
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
    },
    async uploadFun() {
      uploadFile({ accept: "image/*", fileSize: 10 }).then(async (res) => {
        let url = await OSSDirectPass({
          name: res.name,
          raw: res,
        });
        this.dataForm.thumbnail = url;
        this.$forceUpdate();
        this.$emit("submit");
      });
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          addTrafficArticle(this.dataForm).then((res) => {
            this.$emit("submit");
            this.clearForm();
            this.dialogVisible = false;
            this.$message({
              message: "添加或编辑成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed #dee3e6;
}
</style>
